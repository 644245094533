import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Computer, Lightbulb, Code } from '@mui/icons-material';

const InnovationSection = () => {
  return (
    <Box sx={{ mt: 8 }}>
      <Paper
        elevation={2}
        sx={{ p: 4, borderRadius: 4, backgroundColor: '#e9f5f2' }}
      >
        <Typography variant="h4" fontWeight="bold">
          Innovation serves as the driving force behind progress.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          It empowers us to tackle challenges, discover untapped opportunities,
          and envision a future filled with possibilities.
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Computer sx={{ fontSize: 100, color: '#4caf50' }} />
              <Typography>Technology</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Lightbulb sx={{ fontSize: 100, color: '#fbc02d' }} />
              <Typography>Ideas</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Code sx={{ fontSize: 100, color: '#2196f3' }} />
              <Typography>Development</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default InnovationSection;

import React from 'react';
import { Box, Typography, Grid, Avatar, Paper } from '@mui/material';
import Simon from '../assets/Simon.jpg';
import Cedric from '../assets/Cedric.jpg';
import Jose from '../assets/Jose.jpg';
import Blessing from '../assets/Blessing.jpg'; // Ensure you have a photo for Maria

const AboutUs = () => {
  return (
    <Box sx={{ mt: 8, textAlign: 'center', px: 2 }}>
      <Typography variant="h3" fontWeight="bold" sx={{ mb: 2 }}>
        About Us
      </Typography>
      <Typography
        variant="body1"
        sx={{ mt: 2, mb: 4, maxWidth: 800, mx: 'auto' }}
      >
        We are a passionate team dedicated to transforming innovative ideas into
        extraordinary solutions. Our mission is to foster creativity, encourage
        collaboration, and drive progress through innovative technology.
      </Typography>

      {/* New Section: Company Overview */}
      <Box sx={{ mt: 4, textAlign: 'left', maxWidth: 800, mx: 'auto' }}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          What We Do
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our company specializes in developing innovative software solutions
          that cater to various industries including healthcare, education, and
          finance. We focus on creating user-friendly applications that enhance
          productivity and streamline operations.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We believe in the power of collaboration and actively engage with our
          clients to ensure our solutions meet their unique needs. Together, we
          are building a brighter future through technology.
        </Typography>
      </Box>

      {/* Team Members */}
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
        {/* Team Member 1: Cedric */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              borderRadius: '50px', // Slightly rounded corners
              maxWidth: 430,
              height: 'auto', // Auto height to avoid content overflow
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Avatar
              alt="Cedric Okito"
              src={Cedric}
              sx={{ width: 160, height: 160, borderRadius: '50%' }} // Avatar remains circular
            />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Cedric Okito
            </Typography>
            <Typography variant="body2" color="text.secondary">
              CEO & Director
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                textAlign: 'justify',
                fontSize: '0.9rem', // Slightly smaller text
                px: 2, // Padding on the sides
              }}
            >
              A visionary leader with over 10 years in the tech industry, Cedric
              is passionate about innovation and has successfully launched
              several startups. He focuses on creating a collaborative and
              creative work environment.
            </Typography>
          </Paper>
        </Grid>

        {/* Team Member 2: Simon */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              borderRadius: '50px', // Rounded corners
              maxWidth: 430,
              height: 'auto', // Auto height
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Avatar
              alt="Simon Onumajuru"
              src={Simon}
              sx={{ width: 160, height: 160, borderRadius: '50%' }}
            />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Simon Onumajuru
            </Typography>
            <Typography variant="body2" color="text.secondary">
              CTO & Founder
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                textAlign: 'justify',
                fontSize: '0.9rem',
                px: 2,
              }}
            >
              A tech enthusiast and software development expert, Simon has a
              background in computer science and has led numerous successful
              projects, driving technological advancements within the company.
            </Typography>
          </Paper>
        </Grid>

        {/* Team Member 3: Jose */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              borderRadius: '50px',
              maxWidth: 430,
              height: 'auto',
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Avatar
              alt="Jose Guerero"
              src={Jose}
              sx={{ width: 160, height: 160, borderRadius: '50%' }}
            />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Jose Guerero
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Senior Software Engineer
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                textAlign: 'justify',
                fontSize: '0.9rem',
                px: 2,
              }}
            >
              With an exceptional talent for solving complex technical
              challenges, Jose excels at crafting innovative solutions. His deep
              expertise and attention to detail ensure the delivery of
              high-quality, scalable software.
            </Typography>
          </Paper>
        </Grid>

        {/* Team Member 4: Blessing */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              borderRadius: '50px',
              maxWidth: 430,
              height: 'auto',
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Avatar
              alt="Blessing Onumajuru"
              src={Blessing}
              sx={{ width: 160, height: 160, borderRadius: '50%' }}
            />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Blessing Onumajuru
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Cloud Security Specialist
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                textAlign: 'justify',
                fontSize: '0.9rem',
                px: 2,
              }}
            >
              Blessing is a Cloud Security Specialist with 5+ years of
              experience. She excels in building secure systems, understanding
              security protocols, and implementing strategies to protect
              customer data in cloud environments.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Mission & Vision */}
      <Box sx={{ mt: 8, textAlign: 'left', maxWidth: 800, mx: 'auto' }}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Our Mission
        </Typography>
        <Typography variant="body1" gutterBottom>
          To empower individuals and organizations by providing cutting-edge
          technological solutions that address real-world problems, inspire
          innovation, and foster creativity.
        </Typography>

        <Typography variant="h4" fontWeight="bold" sx={{ mt: 4 }} gutterBottom>
          Our Vision
        </Typography>
        <Typography variant="body1" gutterBottom>
          To be the leading innovators in tech, helping create a future where
          technology and ideas unite to solve challenges and improve lives
          worldwide.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutUs;

import React from 'react';
import { Box, Typography, Button, Grid, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  EmojiPeople,
  WorkspacePremium,
  DesignServices,
} from '@mui/icons-material';

const HeroSection = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h2" fontWeight="bold">
        Turning innovative ideas into amazing solutions
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 4 }}>
        Unlocking the Power of Innovation: Transforming Ideas into Extraordinary
        Solutions
      </Typography>
      <Link to="/portfolio" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="success" size="large">
          Our Solution
        </Button>
      </Link>

      {/* Circle Images using Material Icons */}
      <Grid container spacing={2} sx={{ mt: 6 }} justifyContent="center">
        <Grid item>
          <Avatar
            alt="Team Collaboration"
            sx={{ width: 100, height: 100, backgroundColor: 'primary.main' }}
          >
            <EmojiPeople sx={{ fontSize: 60, color: 'white' }} />
          </Avatar>
        </Grid>
        <Grid item>
          <Avatar
            alt="Creative Workspace"
            sx={{ width: 100, height: 100, backgroundColor: 'secondary.main' }}
          >
            <WorkspacePremium sx={{ fontSize: 60, color: 'white' }} />
          </Avatar>
        </Grid>
        <Grid item>
          <Avatar
            alt="Design Solutions"
            sx={{ width: 100, height: 100, backgroundColor: 'success.main' }}
          >
            <DesignServices sx={{ fontSize: 60, color: 'white' }} />
          </Avatar>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSection;

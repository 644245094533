import React from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
} from '@mui/material';

const Portfolio = () => {
  return (
    <Box sx={{ padding: 4, backgroundColor: '#f0f0f0' }}>
      <Typography variant="h3" align="center" gutterBottom>
        Our Portfolio
      </Typography>

      <Typography variant="h5" align="center" paragraph sx={{ color: '#555' }}>
        Introducing LeaseSphere - Your Self-Drive Urban Mobility Platform
      </Typography>

      <Typography
        variant="body1"
        align="center"
        paragraph
        sx={{ color: '#333' }} // Default color for body text
      >
        Our self-drive urban mobility platform offers a comprehensive suite of
        rental management services. These include managing rent payments,
        providing detailed overviews, and offering in-depth analytics of your
        rented properties.
      </Typography>

      <Grid container spacing={4} sx={{ marginTop: 2 }}>
        {[
          {
            title: 'Manage Rent Payments',
            description:
              'Efficiently track and manage all your rent payments with ease, ensuring you stay up-to-date with tenant obligations.',
          },
          {
            title: 'Tenant Tracking',
            description:
              'Keep track of tenant status, clearly indicating which tenants are current and which are not, for a streamlined rental process.',
          },
          {
            title: 'In-Depth Analytics',
            description:
              'Analyze rental trends and performance to make informed decisions and maximize your rental income.',
          },
          {
            title: 'Comprehensive Overview',
            description:
              'Get a detailed overview of your properties, including payment statuses, tenant obligations, and important dates.',
          },
          {
            title: 'Payment Monitoring',
            description:
              'Keep an eye on your properties, who owes you, deposits, arrears, rent due dates, and more.',
          },
          {
            title: 'Streamlined Operations',
            description:
              'Streamline your operations to enhance efficiency and focus on what matters most - maximizing your rental income.',
          },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ color: '#333' }}>
                  {' '}
                  {/* Default color for card titles */}
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: '#555' }}>
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box textAlign="center" marginTop={4}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#4caf50', // Green color for the button
            '&:hover': { backgroundColor: '#388e3c' }, // Darker green on hover
            color: '#fff', // Ensures text is readable
          }}
          href="https://www.leasesphere.com"
          target="_blank"
        >
          Visit LeaseSphere
        </Button>
      </Box>
    </Box>
  );
};

export default Portfolio;

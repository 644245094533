import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        textAlign: 'center',
        py: 4,
        backgroundColor: '#2d3748',
        color: '#fff',
        mt: 8,
      }}
    >
      <Typography variant="body1">
        &copy; {currentYear} Genesis Tech. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;

import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, fontWeight: 'bold', color: '#2E7D32' }}
        >
          Genesis Tech
        </Typography>
        <Box>
          <Button component={Link} to="/" color="inherit" sx={{ mx: 1 }}>
            Home
          </Button>
          <Button component={Link} to="/about" color="inherit" sx={{ mx: 1 }}>
            About Us
          </Button>
          <Button
            component={Link}
            to="/solutions"
            color="inherit"
            sx={{ mx: 1 }}
          >
            Solutions
          </Button>
          <Button
            component={Link}
            to="/portfolio"
            color="inherit"
            sx={{ mx: 1 }}
          >
            Portfolio
          </Button>
        </Box>
        <Button
          variant="contained"
          color="success"
          component={Link}
          to="/contact"
        >
          Get Started
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BuildIcon from '@mui/icons-material/Build';
import InsightsIcon from '@mui/icons-material/Insights';

const Solutions = () => {
  return (
    <Box sx={{ mt: 8, textAlign: 'center', padding: 2 }}>
      <Typography variant="h3" fontWeight="bold">
        Our Solutions
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        We offer a range of innovative solutions designed to tackle challenges
        and drive success across various industries.
      </Typography>

      {/* Solutions Grid */}
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 4, borderRadius: 4, backgroundColor: '#e9f5f2' }}
          >
            <LightbulbIcon sx={{ fontSize: 40, color: 'green' }} />
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Innovation Strategy
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              We work with you to build a strategy that fosters innovation,
              helping your business stay ahead of the curve. Our approach
              includes workshops, brainstorming sessions, and strategic planning
              to ensure sustainable growth and development.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 4, borderRadius: 4, backgroundColor: '#e9f5f2' }}
          >
            <BuildIcon sx={{ fontSize: 40, color: 'green' }} />
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Custom Development
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              We design and build custom software solutions that meet your
              unique business needs and challenges. From web applications to
              mobile platforms, our team of experts delivers high-quality
              solutions tailored to your requirements.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 4, borderRadius: 4, backgroundColor: '#e9f5f2' }}
          >
            <InsightsIcon sx={{ fontSize: 40, color: 'green' }} />
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Data Insights
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Unlock the full potential of your data to gain actionable insights
              and make informed decisions. We utilize advanced analytics tools
              and methodologies to help you understand your data and its
              implications for your business.
            </Typography>
          </Paper>
        </Grid>

        {/* Add more solutions if needed */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 4, borderRadius: 4, backgroundColor: '#e9f5f2' }}
          >
            <LightbulbIcon sx={{ fontSize: 40, color: 'green' }} />
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Cloud Solutions
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Transition to cloud technology for increased scalability and
              efficiency. We offer cloud migration, management, and support
              services tailored to your business needs.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 4, borderRadius: 4, backgroundColor: '#e9f5f2' }}
          >
            <BuildIcon sx={{ fontSize: 40, color: 'green' }} />
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Mobile Solutions
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Enhance your customer engagement through tailored mobile
              applications. We specialize in creating user-friendly apps that
              improve customer interaction and satisfaction.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 4, borderRadius: 4, backgroundColor: '#e9f5f2' }}
          >
            <InsightsIcon sx={{ fontSize: 40, color: 'green' }} />
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Digital Marketing
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Boost your online presence with our digital marketing solutions.
              From SEO to social media marketing, we create strategies that
              drive traffic and generate leads.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Solutions;
